import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./ThenAndNow.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import Tile from "./../../../../res/thennow/tile.png";
import InSitu1 from "./../../../../res/thennow/insitu-1.jpg";
import InSitu2 from "./../../../../res/thennow/insitu-2.jpg";
import InSitu3 from "./../../../../res/thennow/insitu-3.jpg";
import InSitu4 from "./../../../../res/thennow/insitu-4.jpg";
import InSitu5 from "./../../../../res/thennow/insitu-5.jpeg";

const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: InSitu2, name: "InSitu2" },
    { src: InSitu3, name: "InSitu3" },
    { src: InSitu4, name: "InSitu4" },
  ],
};

const ThenAndNow = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="ThenNow"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>THEN & NOW<h2 style={{paddingLeft: "16px"}}></h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="ThenNow"/>
        <br />
        <p>
        The Ghost Galerie is pleased to present its new exhibition: Then and Now, New York Street Style. A "different continuity" of its summer exhibition: New York Street Style, creation of the 80s. An exhibition that allows us to see, on the 3 floors of the gallery, about forty works from the 80's to nowadays, from about twenty artists. An artistic discovery mixing paintings, works on paper and sculptures, gathered around 3 styles: abstract art, figurative and lettering. Not to mention photography and installations! Were these works present during the first hanging ? Not sure. Are they still in conversation with their neighbors ? It's not easy to say. Were the creators of these neighbors already exhibited? To be seen. Will they remain throughout the exhibition? Nothing is less sure. To be discovered until November 15th at 62 rue du faubourg Saint-Honoré, Paris 8ème.</p>
          {/* <a className={DownloaderWrapper} href={DazePdf}download rel="noopener noreferrer" target="_blank">
              Télécharger le communiqué de presse
          </a> */}
        </div>
      </div>
      <br />
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default ThenAndNow;
